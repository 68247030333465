<template>
  <div>
    <!-- Pre-fill from existing contacts ---------------------------------------------------------->
    <b-row v-if="displayField('personDropdown') && contactDropdownVisible">
      <b-col xs="12">
        <b-form-select
          v-model="person"
          :options="peopleOptions"
          :disabled="isDisabledFieldComponent(firstName)"
          @input="personChosen()"
        >
          <template slot="first">
            <option :value="personDefault()" disabled>
              -- Select a person --
            </option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>

    <b-form-group class="pdf-schema-form-person-group" :disabled="useHint">
      <validation-observer ref="observer" v-slot="{ invalid }" />

      <!-- First Name ------------------------------------------------------------------------------->
      <b-row
        v-if="displayField(firstName)"
        :tabindex="schemaTabIndex(firstNameField)"
        class="sortable-field-container"
      >
        <b-col xs="12">
          <validation-provider
            v-slot="validationContext"
            :rules="validationRulesForFieldId(firstNameField)"
            name="First Name"
          >
            <b-form-input
              v-model="formData[firstNameField]"
              placeholder="First Name"
              :state="validationState(validationContext)"
              :disabled="isDisabledFieldComponent(firstName)"
              @keydown.enter.prevent="handleEnterPressed"
              @focus="setCurrentFieldById(firstNameField)"
            />
            <p v-if="errors('First Name')" class="invalid">
              {{ errors('First Name') }}
            </p>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Last Name -------------------------------------------------------------------------------->
      <b-row
        v-if="displayField(lastName)"
        :tabindex="schemaTabIndex(lastNameField)"
        class="sortable-field-container"
      >
        <b-col xs="12">
          <validation-provider
            v-slot="validationContext"
            :rules="validationRulesForFieldId(lastNameField)"
            name="Last Name"
          >
            <b-form-input
              v-model="formData[lastNameField]"
              placeholder="Last Name"
              :state="validationState(validationContext)"
              :disabled="isDisabledFieldComponent(lastName)"
              @keydown.enter.prevent="handleEnterPressed"
              @focus="setCurrentFieldById(lastNameField)"
            />
            <p v-if="errors('Last Name')" class="invalid">
              {{ errors('Last Name') }}
            </p>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Company Name ----------------------------------------------------------------------------->
      <b-row
        v-if="displayField('company_name')"
        :tabindex="schemaTabIndex(fieldIds.company_name)"
        class="sortable-field-container"
      >
        <b-col xs="12">
          <validation-provider
            v-slot="validationContext"
            :rules="validationRulesForFieldId(fieldIds.company_name)"
            name="Company Name"
          >
            <b-form-input
              v-model="formData[fieldIds.company_name]"
              placeholder="Company Name"
              :state="validationState(validationContext)"
              :disabled="isDisabledFieldComponent('company_name')"
              @keydown.enter.prevent="handleEnterPressed"
              @focus="setCurrentFieldById(fieldIds.company_name)"
            />
            <p v-if="errors('Company Name')" class="invalid">
              {{ errors('Company Name') }}
            </p>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Title ------------------------------------------------------------------------------------>
      <b-row
        v-if="displayField('title')"
        :tabindex="schemaTabIndex(fieldIds.title)"
        class="sortable-field-container"
      >
        <b-col xs="12">
          <validation-provider
            v-slot="validationContext"
            :rules="validationRulesForFieldId(fieldIds.title)"
            name="Title"
          >
            <b-form-input
              v-model="formData[fieldIds.title]"
              :state="validationState(validationContext)"
              :disabled="isDisabledFieldComponent('title')"
              placeholder="Title"
              @keydown.enter.prevent="handleEnterPressed"
              @focus="setCurrentFieldById(fieldIds.title)"
            />
            <p v-if="errors('Title')" class="invalid">
              {{ errors('Title') }}
            </p>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Phone Number ----------------------------------------------------------------------------->
      <b-row
        v-if="displayField('phone_number') && phoneField"
        :tabindex="schemaTabIndex(fieldIds.phone_number)"
        class="sortable-field-container"
      >
        <b-col xs="12">
          <validation-provider
            v-slot="validationContext"
            :rules="validationRulesForFieldId(fieldIds.phone_number)"
            name="Phone Number"
          >
            <b-form-input
              v-model="formData[fieldIds.phone_number]"
              v-mask="'###############'"
              type="text"
              :state="validationState(validationContext)"
              :disabled="isDisabledFieldComponent('phone_number')"
              placeholder="Phone Number"
              @keydown.enter.prevent="handleEnterPressed"
              @focus="setCurrentFieldById(fieldIds.phone_number)"
            />
            <p v-if="errors('Phone Number')" class="invalid">
              {{ errors('Phone Number') }}
            </p>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Email Address ---------------------------------------------------------------------------->
      <b-row
        v-if="displayField('email_address')"
        :tabindex="schemaTabIndex(fieldIds.email_address)"
        class="sortable-field-container"
      >
        <b-col xs="12">
          <validation-provider
            v-slot="validationContext"
            :rules="validationRulesForFieldId(fieldIds.email_address)"
            name="Email"
          >
            <b-form-input
              v-model="formData[fieldIds.email_address]"
              type="email"
              :state="validationState(validationContext)"
              :disabled="isDisabledFieldComponent('email_address')"
              placeholder="Email"
              @keydown.enter.prevent="handleEnterPressed"
              @focus="setCurrentFieldById(fieldIds.email_address)"
            />
            <p v-if="errors('Email')" class="invalid">
              {{ errors('Email') }}
            </p>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Company Ownership Percentage  ------------------------------------------------------------>
      <b-row
        v-if="displayField('company_ownership_percentage')"
        :tabindex="schemaTabIndex(fieldIds.company_ownership_percentage)"
        class="sortable-field-container"
      >
        <b-col xs="12">
          <validation-provider
            v-slot="validationContext"
            :rules="validationRulesForFieldId(fieldIds.company_ownership_percentage)"
            name="Company Ownership Percentage"
          >
            <b-form-input
              v-model="formData[fieldIds.company_ownership_percentage]"
              type="number"
              :state="validationState(validationContext)"
              :disabled="isDisabledFieldComponent('company_ownership_percentage')"
              placeholder="Company Ownership Percentage"
              @keydown.enter.prevent="handleEnterPressed"
              @focus="setCurrentFieldById(fieldIds.company_ownership_percentage)"
            />
            <p v-if="errors('Company Ownership Percentage')" class="invalid">
              {{ errors('Company Ownership Percentage') }}
            </p>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Bio  ------------------------------------------------------------------------------------->
      <b-row
        v-if="displayField('bio')"
        :tabindex="schemaTabIndex(fieldIds.bio)"
        class="sortable-field-container"
      >
        <b-col xs="12">
          <validation-provider
            v-slot="validationContext"
            :rules="validationRulesForFieldId(fieldIds.bio)"
            name="Bio"
          >
            <b-form-textarea
              v-model="formData[fieldIds.bio]"
              class="text-area-input"
              :max-length="bioField.meta.character_limit"
              no-resize
              :state="validationState(validationContext)"
              :disabled="isDisabledFieldComponent('bio')"
              placeholder="Bio"
              @keydown.enter.prevent="handleEnterPressed"
              @focus="setCurrentFieldById(fieldIds.bio)"
            />
            <p v-if="errors('Bio')" class="invalid">
              {{ errors('Bio') }}
            </p>
          </validation-provider>
        </b-col>
      </b-row>

      <pdf-schema-form-address-group
        :fields="fields"
        :contextual-jurisdiction="contextualJurisdiction"
        :use-hint="useHint"
        :show-title="false"
        @enterPressed="$emit('enterPressed', $event)"
      />
    </b-form-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { getFieldSuggestionValue } from '@/components/StagelineV2/schemaForm/pdf/helper'

export default {
  name: 'PdfSchemaFormPersonGroup',
  components: {
    PdfSchemaFormAddressGroup: () => import('@/components/StagelineV2/schemaForm/pdf/fieldGroups/PdfSchemaFormAddressGroup'),
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    useHint: {
      type: Boolean,
      default: false,
    },
    showContactDropdown: {
      type: Boolean,
      default: true,
    },
    contextualJurisdiction: null,
  },
  data() {
    return {
      fieldIds: {},
      person: this.personDefault(),
    }
  },
  computed: {
    ...mapGetters('stagelineSchemaForm', [
      'formData',
      'mapFieldIdsToGroupedFieldsParts',
      'isDisabledField',
      'validationRulesForFieldId',
      'validationState',
      'resourceFields',
      'schemaTabIndex',
      'resourceFields',
    ]),
    ...mapGetters('account', ['peopleWithAllRequiredInfo', 'emailFor', 'phoneFor']),
    ...mapGetters('jurisdictions', ['convertToBaseJurisdiction']),
    ...mapGetters('companies', ['hasProductOfTypeWithCompanyOrInCart']),

    initialField() {
      return this.fields[0]
    },
    title() {
      return  this.initialField.title || this.initialField.original_field_label
    },
    phoneField() {
      return this.fields.find(f => f?.data?.parts[0].includes('phone_number'))
    },
    bioField() {
      return this.fields.find(f => f?.data?.parts[0].includes('bio'))
    },
    firstName() {
      return this.displayField('contact_first_name') ?
        'contact_first_name' :
        'first_name'
    },
    lastName() {
      return this.displayField('contact_last_name') ?
        'contact_last_name' :
        'last_name'
    },
    firstNameField() {
      return this.firstName === 'contact_first_name' ?
        this.fieldIds.contact_first_name :
        this.fieldIds.first_name
    },
    lastNameField() {
      return this.lastName === 'contact_last_name' ?
        this.fieldIds.contact_last_name :
        this.fieldIds.last_name
    },
    peopleOptions() {
      const options = this.peopleWithAllRequiredInfo.map(p => ({
        text: this.displayName(p),
        value: {
          first_name: p.first_name,
          last_name: p.last_name,
          name: `${p.first_name} ${p.last_name}`,
          id: p.id,
          official_is_company: p.contact_is_company || false,
          company_name: p.contact_is_company ? p.contact_company_name : '',
          substitute_ra_address: p.substitute_ra_address,
          address: p.person_addresses.find(pa => pa.kind === 'primary') || p.person_addresses[0],
          phone_number: this.phoneFor(p),
          email_address: this.emailFor(p),
        },
      }))

      options.unshift({ text: `Add a new Contact`, value: '' })
      const noOfficialIsCompany = options.filter(person => !person.value.official_is_company)
      return this.initialField.name === 'responsible_party' ? noOfficialIsCompany : options
    },
    contactDropdownVisible() {
      return this.showContactDropdown &&
        !this.useHint &&
        !this.isDisabledFieldComponent(this.firstName)
    },
  },
  beforeMount() {
    this.fieldIds = this.mapFieldIdsToGroupedFieldsParts(this.fields)
  },
  mounted() {
    if (this.contactDropdownVisible) this.setPerson(false)
  },
  methods: {
    ...mapActions('stagelineSchemaForm', [
      'setCurrentFieldById',
      'setFormData',
    ]),
    displayField(fieldName) {
      const standardPersonFields = ['first_name', 'last_name', 'name']
      const fieldsToCheck = (fieldName === 'personDropdown') ? standardPersonFields : [fieldName]
      return fieldsToCheck.some(field => Object.keys(this.fieldIds).includes(field))
    },
    isDisabledFieldComponent(fieldName) {
      return this.isDisabledField(this.fields.find(f => f.id === this.fieldIds[fieldName]))
    },
    errors(fieldName) {
      return this.$refs.observer?.$_veeObserver?.errors && this.$refs.observer.$_veeObserver.errors[fieldName] ?
        this.$refs.observer.$_veeObserver.errors[fieldName].filter(error => !error.includes('required'))[0] :
        null
    },
    handleEnterPressed(e) {
      this.$emit('enterPressed', e.shiftKey ? 'prev' : 'next')
    },

    /*********** Person Dropdown methods  *******************/
    setPerson(setDefaultPerson = true) {
      const person = this.peopleOptions
        .find(p => {
          const pFirstName = p.value.first_name && p.value.first_name.trim()
          const pLastName = p.value.last_name && p.value.last_name.trim()
          const firstName = this.formData[this.firstNameField]
          const lastName =  this.formData[this.lastNameField]
          return pFirstName && pFirstName === firstName && pLastName && pLastName === lastName
        })

      if (person?.value) this.person = person.value
      else if (setDefaultPerson) this.person = this.personDefault()
    },
    personChosen() {
      let useRaAddress = false
      // If add a contact is chosen
      if (typeof(this.person) !== 'object' && this.person === '') {
        this.person = this.personDefault()
        this.$emit('show-contact-modal')
      } else {
        if (this.person?.substitute_ra_address && this.initialField?.meta?.suggestion) {
          const jurisdiction = this.convertToBaseJurisdiction(this.contextualJurisdiction)
          if (this.hasProductOfTypeWithCompanyOrInCart('registered-agent', jurisdiction)) {
            useRaAddress = true
          }
        }

        if (this.person.first_name !== this.formData[this.firstNameField] || this.person.last_name !== this.formData[this.lastNameField]) {
          this.onInput(false, useRaAddress)
        }
      }
    },
    onInput(triggeredFromHint, useRaAddress = false) {
      if (triggeredFromHint) {
        if (this.person) this.person.address = triggeredFromHint.value
      } else {
        if (this.person?.address && this.person?.address?.value) {
          this.person.address = this.person.address.value
        }
      }

      const valueToEmit = this.disabled ? this.value : this.person
      const personFieldsToPrefill = this.resourceFields
        .filter(rf =>
          rf?.glossary_term_name === this.initialField?.glossary_term_name &&
          rf?.meta?.title === this.initialField?.meta?.title &&
          rf?.data?.group_id === this.initialField?.data?.group_id
        )

      const newFormData = cloneDeep(this.formData)

      personFieldsToPrefill.forEach(resourceField => {
        const partName = resourceField?.data?.parts[0]
        if (partName) {
          const nestedValue = this.getNestedValue(valueToEmit, partName)
          if (useRaAddress && getFieldSuggestionValue(resourceField)) {
            resourceField.data.disabled = true
            newFormData[resourceField.id] = getFieldSuggestionValue(resourceField)
          } else if (nestedValue !== undefined) {
            resourceField.data.disabled = false
            newFormData[resourceField.id] = nestedValue
          }
        }
      })
      this.setFormData(newFormData)
    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj)
    },
    personDefault() {
      return {
        first_name: null,
        last_name: null,
        substitute_ra_address: false,
        address: {
          line1: null,
          line2: null,
          city: null,
          state_province_region: null,
          zip_postal_code: null,
          country: null,
        },
        email_address: null,
        phone_number: null,
      }
    },
    displayName(person) {
      if (person.contact_is_company) {
        return person.contact_company_name
      }
      return person.first_name + ' ' + person.last_name
    },
  },
}
</script>
<style>
.pdf-schema-form-person-group {
  margin-bottom: 0;
}
.invalid {
  font-size: 80%;
  color: #dc3545;
  margin-bottom: 0;
}
.text-area-input {
  height: 7.5em !important;
}
</style>
