var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.displayField("personDropdown") && _vm.contactDropdownVisible
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { xs: "12" } },
                [
                  _c(
                    "b-form-select",
                    {
                      attrs: {
                        options: _vm.peopleOptions,
                        disabled: _vm.isDisabledFieldComponent(_vm.firstName),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.personChosen()
                        },
                      },
                      model: {
                        value: _vm.person,
                        callback: function ($$v) {
                          _vm.person = $$v
                        },
                        expression: "person",
                      },
                    },
                    [
                      _c("template", { slot: "first" }, [
                        _c(
                          "option",
                          {
                            attrs: { disabled: "" },
                            domProps: { value: _vm.personDefault() },
                          },
                          [
                            _vm._v(
                              "\n            -- Select a person --\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-form-group",
        {
          staticClass: "pdf-schema-form-person-group",
          attrs: { disabled: _vm.useHint },
        },
        [
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return undefined
                },
              },
            ]),
          }),
          _vm.displayField(_vm.firstName)
            ? _c(
                "b-row",
                {
                  staticClass: "sortable-field-container",
                  attrs: { tabindex: _vm.schemaTabIndex(_vm.firstNameField) },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: _vm.validationRulesForFieldId(
                            _vm.firstNameField
                          ),
                          name: "First Name",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (validationContext) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: "First Name",
                                      state:
                                        _vm.validationState(validationContext),
                                      disabled: _vm.isDisabledFieldComponent(
                                        _vm.firstName
                                      ),
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.handleEnterPressed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.setCurrentFieldById(
                                          _vm.firstNameField
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formData[_vm.firstNameField],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          _vm.firstNameField,
                                          $$v
                                        )
                                      },
                                      expression: "formData[firstNameField]",
                                    },
                                  }),
                                  _vm.errors("First Name")
                                    ? _c("p", { staticClass: "invalid" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.errors("First Name")) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1929374397
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.displayField(_vm.lastName)
            ? _c(
                "b-row",
                {
                  staticClass: "sortable-field-container",
                  attrs: { tabindex: _vm.schemaTabIndex(_vm.lastNameField) },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: _vm.validationRulesForFieldId(
                            _vm.lastNameField
                          ),
                          name: "Last Name",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (validationContext) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: "Last Name",
                                      state:
                                        _vm.validationState(validationContext),
                                      disabled: _vm.isDisabledFieldComponent(
                                        _vm.lastName
                                      ),
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.handleEnterPressed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.setCurrentFieldById(
                                          _vm.lastNameField
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formData[_vm.lastNameField],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          _vm.lastNameField,
                                          $$v
                                        )
                                      },
                                      expression: "formData[lastNameField]",
                                    },
                                  }),
                                  _vm.errors("Last Name")
                                    ? _c("p", { staticClass: "invalid" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.errors("Last Name")) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          237880221
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.displayField("company_name")
            ? _c(
                "b-row",
                {
                  staticClass: "sortable-field-container",
                  attrs: {
                    tabindex: _vm.schemaTabIndex(_vm.fieldIds.company_name),
                  },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: _vm.validationRulesForFieldId(
                            _vm.fieldIds.company_name
                          ),
                          name: "Company Name",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (validationContext) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: "Company Name",
                                      state:
                                        _vm.validationState(validationContext),
                                      disabled:
                                        _vm.isDisabledFieldComponent(
                                          "company_name"
                                        ),
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.handleEnterPressed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.setCurrentFieldById(
                                          _vm.fieldIds.company_name
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.formData[_vm.fieldIds.company_name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          _vm.fieldIds.company_name,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData[fieldIds.company_name]",
                                    },
                                  }),
                                  _vm.errors("Company Name")
                                    ? _c("p", { staticClass: "invalid" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.errors("Company Name")) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          916565474
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.displayField("title")
            ? _c(
                "b-row",
                {
                  staticClass: "sortable-field-container",
                  attrs: { tabindex: _vm.schemaTabIndex(_vm.fieldIds.title) },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: _vm.validationRulesForFieldId(
                            _vm.fieldIds.title
                          ),
                          name: "Title",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (validationContext) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      state:
                                        _vm.validationState(validationContext),
                                      disabled:
                                        _vm.isDisabledFieldComponent("title"),
                                      placeholder: "Title",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.handleEnterPressed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.setCurrentFieldById(
                                          _vm.fieldIds.title
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formData[_vm.fieldIds.title],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          _vm.fieldIds.title,
                                          $$v
                                        )
                                      },
                                      expression: "formData[fieldIds.title]",
                                    },
                                  }),
                                  _vm.errors("Title")
                                    ? _c("p", { staticClass: "invalid" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.errors("Title")) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3560476477
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.displayField("phone_number") && _vm.phoneField
            ? _c(
                "b-row",
                {
                  staticClass: "sortable-field-container",
                  attrs: {
                    tabindex: _vm.schemaTabIndex(_vm.fieldIds.phone_number),
                  },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: _vm.validationRulesForFieldId(
                            _vm.fieldIds.phone_number
                          ),
                          name: "Phone Number",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (validationContext) {
                                return [
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "###############",
                                        expression: "'###############'",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      state:
                                        _vm.validationState(validationContext),
                                      disabled:
                                        _vm.isDisabledFieldComponent(
                                          "phone_number"
                                        ),
                                      placeholder: "Phone Number",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.handleEnterPressed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.setCurrentFieldById(
                                          _vm.fieldIds.phone_number
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.formData[_vm.fieldIds.phone_number],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          _vm.fieldIds.phone_number,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData[fieldIds.phone_number]",
                                    },
                                  }),
                                  _vm.errors("Phone Number")
                                    ? _c("p", { staticClass: "invalid" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.errors("Phone Number")) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3849566824
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.displayField("email_address")
            ? _c(
                "b-row",
                {
                  staticClass: "sortable-field-container",
                  attrs: {
                    tabindex: _vm.schemaTabIndex(_vm.fieldIds.email_address),
                  },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: _vm.validationRulesForFieldId(
                            _vm.fieldIds.email_address
                          ),
                          name: "Email",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (validationContext) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "email",
                                      state:
                                        _vm.validationState(validationContext),
                                      disabled:
                                        _vm.isDisabledFieldComponent(
                                          "email_address"
                                        ),
                                      placeholder: "Email",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.handleEnterPressed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.setCurrentFieldById(
                                          _vm.fieldIds.email_address
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.formData[
                                          _vm.fieldIds.email_address
                                        ],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          _vm.fieldIds.email_address,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData[fieldIds.email_address]",
                                    },
                                  }),
                                  _vm.errors("Email")
                                    ? _c("p", { staticClass: "invalid" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.errors("Email")) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          414877878
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.displayField("company_ownership_percentage")
            ? _c(
                "b-row",
                {
                  staticClass: "sortable-field-container",
                  attrs: {
                    tabindex: _vm.schemaTabIndex(
                      _vm.fieldIds.company_ownership_percentage
                    ),
                  },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: _vm.validationRulesForFieldId(
                            _vm.fieldIds.company_ownership_percentage
                          ),
                          name: "Company Ownership Percentage",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (validationContext) {
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "number",
                                      state:
                                        _vm.validationState(validationContext),
                                      disabled: _vm.isDisabledFieldComponent(
                                        "company_ownership_percentage"
                                      ),
                                      placeholder:
                                        "Company Ownership Percentage",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.handleEnterPressed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.setCurrentFieldById(
                                          _vm.fieldIds
                                            .company_ownership_percentage
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.formData[
                                          _vm.fieldIds
                                            .company_ownership_percentage
                                        ],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          _vm.fieldIds
                                            .company_ownership_percentage,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formData[fieldIds.company_ownership_percentage]",
                                    },
                                  }),
                                  _vm.errors("Company Ownership Percentage")
                                    ? _c("p", { staticClass: "invalid" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.errors(
                                                "Company Ownership Percentage"
                                              )
                                            ) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1755070832
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.displayField("bio")
            ? _c(
                "b-row",
                {
                  staticClass: "sortable-field-container",
                  attrs: { tabindex: _vm.schemaTabIndex(_vm.fieldIds.bio) },
                },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12" } },
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: _vm.validationRulesForFieldId(
                            _vm.fieldIds.bio
                          ),
                          name: "Bio",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (validationContext) {
                                return [
                                  _c("b-form-textarea", {
                                    staticClass: "text-area-input",
                                    attrs: {
                                      "max-length":
                                        _vm.bioField.meta.character_limit,
                                      "no-resize": "",
                                      state:
                                        _vm.validationState(validationContext),
                                      disabled:
                                        _vm.isDisabledFieldComponent("bio"),
                                      placeholder: "Bio",
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        $event.preventDefault()
                                        return _vm.handleEnterPressed.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      focus: function ($event) {
                                        return _vm.setCurrentFieldById(
                                          _vm.fieldIds.bio
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.formData[_vm.fieldIds.bio],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          _vm.fieldIds.bio,
                                          $$v
                                        )
                                      },
                                      expression: "formData[fieldIds.bio]",
                                    },
                                  }),
                                  _vm.errors("Bio")
                                    ? _c("p", { staticClass: "invalid" }, [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.errors("Bio")) +
                                            "\n          "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3195596270
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("pdf-schema-form-address-group", {
            attrs: {
              fields: _vm.fields,
              "contextual-jurisdiction": _vm.contextualJurisdiction,
              "use-hint": _vm.useHint,
              "show-title": false,
            },
            on: {
              enterPressed: function ($event) {
                return _vm.$emit("enterPressed", $event)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }